(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/draw-handler/common/functional.js') >= 0) return;  svs.modules.push('/components/sport/draw-handler/common/functional.js');
'use strict';

const compose = function () {
  for (var _len = arguments.length, fn = new Array(_len), _key = 0; _key < _len; _key++) {
    fn[_key] = arguments[_key];
  }
  return args => fn.reduceRight((arg, fn) => fn(arg), args);
};
const pipe = function () {
  for (var _len2 = arguments.length, fns = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
    fns[_key2] = arguments[_key2];
  }
  return args => fns.reduce((arg, fn) => fn(arg), args);
};
const toExport = {
  compose,
  pipe
};
if (svs.isServer) {
  module.exports = toExport;
} else {
  setGlobal('svs.components.sport.drawHandler.common.functional', toExport);
}

 })(window);