(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/draw-handler/client/create-draw-handler.js') >= 0) return;  svs.modules.push('/components/sport/draw-handler/client/create-draw-handler.js');
'use strict';

var _svs$draw$data$draws$, _svs;
const {
  createDrawModel,
  createDrawHandlerBase,
  functional: {
    pipe
  }
} = svs.components.sport.drawHandler.common;
const {
  createJupiterInstance,
  createJupiterSocketInstance
} = svs.components.sport.drawHandler.client;
const drawsData = (_svs$draw$data$draws$ = (_svs = svs) === null || _svs === void 0 || (_svs = _svs.draw) === null || _svs === void 0 || (_svs = _svs.data) === null || _svs === void 0 || (_svs = _svs.draws) === null || _svs === void 0 ? void 0 : _svs.draws) !== null && _svs$draw$data$draws$ !== void 0 ? _svs$draw$data$draws$ : {};
const jupiter = createJupiterInstance();
const socket = createJupiterSocketInstance();
const createDrawHandler = function () {
  let privateInterface = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return function () {
    let publicInterface = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    for (const [, drawData] of Object.entries(drawsData)) {
      const draw = createDrawModel(drawData);
      privateInterface.drawCache.setModel(draw);
    }
    return publicInterface;
  };
};

const privateInterface = {
  jupiter,
  socket
};
const fns = [createDrawHandlerBase, createDrawHandler].map(fn => fn(privateInterface));
const drawHandlerCreator = pipe(...fns);
setGlobal('svs.components.sport.drawHandler.client.createDrawHandler', drawHandlerCreator);

 })(window);