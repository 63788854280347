(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/draw-handler/common/constants.js') >= 0) return;  svs.modules.push('/components/sport/draw-handler/common/constants.js');
'use strict';

const LOGGER_NAME = 'sport:draw-handler';
const DRAW_CACHE_TIME = 60 * 1000;
const toExport = {
  LOGGER_NAME,
  DRAW_CACHE_TIME
};
if (svs.isServer) {
  module.exports = toExport;
} else {
  setGlobal('svs.components.sport.drawHandler.common.constants', toExport);
}

 })(window);