(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/draw-handler/common/create-draw-model.js') >= 0) return;  svs.modules.push('/components/sport/draw-handler/common/create-draw-model.js');
'use strict';

let resolveTimeZone;
let constants;
if (svs.isServer) {
  const trinidad = require('trinidad-core').core;
  constants = require('./constants.es6');
  resolveTimeZone = trinidad.components.require('lb-utils', 'helper-formatdate').api.resolveTimeZone;
} else {
  resolveTimeZone = svs.components.lbUtils.helpers.formatDate.resolveTimeZone;
  constants = svs.components.sport.drawHandler.common.constants;
}
const drawSymbol = Symbol('DrawModel');
const isDrawModel = instance => (instance === null || instance === void 0 ? void 0 : instance._symbol) === drawSymbol;
const createDrawId = (productId, drawNumber) => "".concat(productId, "_").concat(drawNumber);
const createDrawModel = data => {
  let model = data === null || data === void 0 ? void 0 : data.model;
  let timestamp = resolveTimeZone((data === null || data === void 0 ? void 0 : data.timestamp) || Date.now());
  const setData = data => {
    timestamp = resolveTimeZone(Date.now());
    model = data;
  };
  const getData = () => model;
  const getProductId = () => model.productId;
  const getDrawNumber = () => model.drawNumber;
  const getTimestamp = () => timestamp;
  const getDrawId = () => createDrawId(model.productId, model.drawNumber);
  const getDrawState = () => model.drawStateId;
  const isOutdated = () => timestamp.getTime() < Date.now() - constants.DRAW_CACHE_TIME;
  return {
    get _symbol() {
      return drawSymbol;
    },
    setData,
    getData,
    getProductId,
    getDrawNumber,
    getTimestamp,
    getDrawId,
    getDrawState,
    isOutdated
  };
};
const toExport = {
  createDrawModel,
  isDrawModel,
  getDrawId: createDrawId
};
if (svs.isServer) {
  module.exports = toExport;
} else {
  setGlobal('svs.components.sport.drawHandler.common', toExport);
}

 })(window);