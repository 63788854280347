(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/draw-handler/draw-handler.js') >= 0) return;  svs.modules.push('/components/sport/draw-handler/draw-handler.js');
'use strict';

let createDrawHandler;
if (svs.isServer) {
  createDrawHandler = require('./server/create-draw-handler');
} else {
  createDrawHandler = svs.components.sport.drawHandler.client.createDrawHandler;
}
let instance;
const drawHandler = () => {
  if (!instance) {
    instance = createDrawHandler();
  }
  return instance;
};
if (svs.isServer) {
  module.exports = drawHandler;
} else {
  setGlobal('svs.components.sport.drawHandler.drawHandler', drawHandler);
}

 })(window);