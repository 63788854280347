(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/draw-handler/client/create-jupiter-socket-instance.js') >= 0) return;  svs.modules.push('/components/sport/draw-handler/client/create-jupiter-socket-instance.js');
'use strict';

const {
  socket
} = svs.core;
const createJupiterSocketInstance = () => {
  const socketContext = {};
  const subscribe = function (path, callback) {
    let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    socket.subscribe(path, callback, socketContext, options.sendInitialUpdate, options.autoReconnect, options.backendFallback);
  };
  const unsubscribe = (path, callback) => {
    socket.unsubscribe(path, callback);
  };
  return {
    subscribe,
    unsubscribe
  };
};
setGlobal('svs.components.sport.drawHandler.client.createJupiterSocketInstance', createJupiterSocketInstance);

 })(window);